<template>
  <div class="procurement">
    <div class="main">
      <div class="title ml-4 mt-2 mb-2">
        <span>现货煤矿采购</span>
        <span class="ml-4" style="color: #FF0000">400-000-0000拨6</span>
      </div>
      <el-tabs v-model="activeName" type="border-card">
        <el-tab-pane label="询价信息" name="4">
          <div class="body-select">
            <el-collapse v-model="activeNames">
              <el-collapse-item name="1" class="collapse-item">
                <template slot="title">
                  <div class='select-title ml-3'>筛选条件</div>
                </template>
                <div class="select-main">
                  <el-form label-width="125px">
                    <el-form-item class="form-item pb-1">
                      <template slot="label">
                        <div class="item-label">采购单位</div>
                      </template>
                      <div class="ml-4">
                        <el-input placeholder="请选择采购单位" style="width:350px"
                                  v-model="purchasingUnit"></el-input>
                      </div>
                    </el-form-item>
                    <el-form-item class="form-item pb-1">
                      <template slot="label">
                        <div class="item-label">煤种</div>
                      </template>
                      <div class="ml-4">
                        <el-select placeholder="请选择煤的种类" style="width:350px" v-model="coal">
                          <el-option v-for="(item,index) in coalList" :key="index"
                                     :label="item.pproductname"
                                     :value="item.pproductid"></el-option>
                        </el-select>
                      </div>
                    </el-form-item>
                    <el-form-item class="form-item pb-1">
                      <template slot="label">
                        <div class="item-label">竞价时间</div>
                      </template>
                      <div class="ml-4">
                        <el-date-picker
                            v-model="timeDate"
                            type="daterange"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            align="right"
                            unlink-panels
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                        >
                        </el-date-picker>
                        <el-button class="ml-4 btn" @click="getSearchList">搜索</el-button>
                      </div>
                    </el-form-item>
<!--                    <el-form-item class="form-item pb-1">-->
<!--                      <el-button class="ml-4 btn" @click="getSearchList">搜索</el-button>-->
<!--                    </el-form-item>-->
                  </el-form>
                </div>
              </el-collapse-item>
            </el-collapse>
          </div>
          <div class="body-select-message p-0">
            <div class="body-select-message">
              <el-table :data="tableData" border style="width: 100%"
                        :header-cell-style="qualityTableStyle">
                <el-table-column label="采购单位" prop="companyname" align="center">
                  <template slot-scope="scope">
                    <router-link
                        :to="{ path: '/product', query: {ptargetid:scope.row.ptargetid,activeName:activeName}}">
                      {{ scope.row.companyname }}
                    </router-link>
                  </template>
                </el-table-column>
                <el-table-column label="竞价名称" prop="bidname" align="center"></el-table-column>
                <el-table-column label="竞价数量" prop="quantity" align="center"></el-table-column>
                <el-table-column label="供货最小数量" prop="minquantity" align="center"></el-table-column>
                <el-table-column label="基准拦标价" prop="price" align="center"></el-table-column>
                <el-table-column label="最小降价单位" prop="minmarkdown" align="center"></el-table-column>
                <el-table-column label="诚意金" prop="deposit" align="center"></el-table-column>
                <el-table-column label="竞价开始时间" prop="begintime" align="center"
                                 width="160"></el-table-column>
                <el-table-column label="竞价结束时间" prop="endtime" align="center"
                                 width="160"></el-table-column>
              </el-table>
            </div>
            <!--    分页-->
            <div class="block body-select-message" align="right">
              <el-pagination
                  @size-change="sizeChangeHandle"
                  @current-change="currentChangeHandle"
                  background
                  :current-page="pageIndex"
                  :page-sizes="[10, 20, 50, 100]"
                  :page-size="pageSize"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="totalPage">
              </el-pagination>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="可报价信息" name="3">
          <div class="body-select">
            <el-collapse v-model="activeNames">
              <el-collapse-item name="1" class="collapse-item">
                <template slot="title">
                  <div class='select-title ml-3'>筛选条件</div>
                </template>
                <div class="select-main">
                  <el-form label-width="125px">
                    <el-form-item class="form-item pb-1">
                      <template slot="label">
                        <div class="item-label">采购单位</div>
                      </template>
                      <div class="ml-4">
                        <el-input placeholder="请选择采购单位" style="width:370px"
                                  v-model="purchasingUnit"></el-input>
                      </div>
                    </el-form-item>
                    <el-form-item class="form-item pb-1">
                      <template slot="label">
                        <div class="item-label">煤种</div>
                      </template>
                      <div class="ml-4">
                        <el-select placeholder="请选择煤的种类" style="width:370px" v-model="coal">
                          <el-option v-for="(item,index) in coalList" :key="index"
                                     :label="item.pproductname"
                                     :value="item.pproductid"></el-option>
                        </el-select>
                      </div>
                    </el-form-item>
                    <el-form-item class="form-item pb-1">
                      <template slot="label">
                        <div class="item-label">竞价时间</div>
                      </template>
                      <div class="ml-4">
                        <el-date-picker
                            v-model="timeDate"
                            type="daterange"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            align="right"
                            unlink-panels
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                        >
                        </el-date-picker>
                        <el-button class="ml-4 btn" @click="getSearchList">搜索</el-button>
                      </div>
                    </el-form-item>
<!--                    <el-form-item class="form-item pb-1">-->
<!--                      <el-button class="ml-4 btn" @click="getSearchList">搜索</el-button>-->
<!--                    </el-form-item>-->
                  </el-form>
                </div>
              </el-collapse-item>
            </el-collapse>
          </div>
          <div class="body-select-message p-0">
            <div class="body-select-message">
              <el-table :data="tableData" border style="width: 100%"
                        :header-cell-style="qualityTableStyle">
                <el-table-column label="采购单位" prop="companyname" align="center">
                  <template slot-scope="scope">
                    <router-link
                        :to="{ path: '/product', query: {ptargetid:scope.row.ptargetid,activeName:activeName}}">
                      {{ scope.row.companyname }}
                    </router-link>
                  </template>
                </el-table-column>
                <el-table-column label="竞价名称" prop="bidname" align="center"></el-table-column>
                <el-table-column label="竞价数量" prop="quantity" align="center"></el-table-column>
                <el-table-column label="供货最小数量" prop="minquantity" align="center"></el-table-column>
                <el-table-column label="基准拦标价" prop="price" align="center"></el-table-column>
                <el-table-column label="最小降价单位" prop="minmarkdown" align="center"></el-table-column>
                <el-table-column label="诚意金" prop="deposit" align="center"></el-table-column>
                <el-table-column label="竞价开始时间" prop="begintime" align="center"
                                 width="160"></el-table-column>
                <el-table-column label="竞价结束时间" prop="endtime" align="center"
                                 width="160"></el-table-column>
              </el-table>
            </div>
            <!--    分页-->
            <div class="block body-select-message" align="right">
              <el-pagination
                  @size-change="sizeChangeHandle"
                  @current-change="currentChangeHandle"
                  background
                  :current-page="pageIndex"
                  :page-sizes="[10, 20, 50, 100]"
                  :page-size="pageSize"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="totalPage">
              </el-pagination>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="询价结果" name="2">
          <div class="body-select">
            <el-collapse v-model="activeNames">
              <el-collapse-item name="1" class="collapse-item">
                <template slot="title">
                  <div class='select-title ml-3'>筛选条件</div>
                </template>
                <div class="select-main">
                  <el-form label-width="125px">
                    <el-form-item class="form-item pb-1">
                      <template slot="label">
                        <div class="item-label">采购单位</div>
                      </template>
                      <div class="ml-4">
                        <el-input placeholder="请选择采购单位" style="width:370px"
                                  v-model="purchasingUnit"></el-input>
                      </div>
                    </el-form-item>
                    <el-form-item class="form-item pb-1">
                      <template slot="label">
                        <div class="item-label">煤种</div>
                      </template>
                      <div class="ml-4">
                        <el-select placeholder="请选择煤的种类" style="width:370px" v-model="coal">
                          <el-option v-for="(item,index) in coalList" :key="index"
                                     :label="item.pproductname"
                                     :value="item.pproductid"></el-option>
                        </el-select>
                      </div>
                    </el-form-item>
                    <el-form-item class="form-item pb-1">
                      <template slot="label">
                        <div class="item-label">竞价时间</div>
                      </template>
                      <div class="ml-4">
                        <el-date-picker
                            v-model="timeDate"
                            type="daterange"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            align="right"
                            unlink-panels
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                        >
                        </el-date-picker>
                      </div>
                    </el-form-item>
                    <el-form-item class="form-item pb-1">
                      <el-button class="ml-4 btn" @click="getSearchList">搜索</el-button>
                    </el-form-item>
                  </el-form>
                </div>
              </el-collapse-item>
            </el-collapse>
          </div>
          <div class="body-select-message p-0">
            <div class="body-select-message">
              <el-table :data="tableData" border style="width: 100%"
                        :header-cell-style="qualityTableStyle">
                <el-table-column label="采购单位" prop="companyname" align="center">
                  <template slot-scope="scope">
                    <router-link
                        :to="{ path: '/product', query: {ptargetid:scope.row.ptargetid,activeName:activeName}}">
                      {{ scope.row.companyname }}
                    </router-link>
                  </template>
                </el-table-column>
                <el-table-column label="竞价名称" prop="bidname" align="center"></el-table-column>
                <el-table-column label="竞价数量" prop="quantity" align="center"></el-table-column>
                <el-table-column label="供货最小数量" prop="minquantity" align="center"></el-table-column>
                <el-table-column label="基准拦标价" prop="price" align="center"></el-table-column>
                <el-table-column label="最小降价单位" prop="minmarkdown" align="center"></el-table-column>
                <el-table-column label="诚意金" prop="deposit" align="center"></el-table-column>
                <el-table-column label="竞价开始时间" prop="begintime" align="center"
                                 width="160"></el-table-column>
                <el-table-column label="竞价结束时间" prop="endtime" align="center"
                                 width="160"></el-table-column>
              </el-table>
            </div>
            <!--    分页-->
            <div class="block body-select-message" align="right">
              <el-pagination
                  @size-change="sizeChangeHandle"
                  @current-change="currentChangeHandle"
                  background
                  :current-page="pageIndex"
                  :page-sizes="[10, 20, 50, 100]"
                  :page-size="pageSize"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="totalPage">
              </el-pagination>
            </div>
          </div>
        </el-tab-pane>
        <!--                <el-tab-pane label="操作手册" name="1">-->
        <!--                    <div class="manual-title m-2">操作手册</div>-->
        <!--                    <div class="manual-btn">-->
        <!--                        <el-button type="primary">点击下载电煤现货采购帮助文档.PDF</el-button>-->
        <!--                        <el-button type="primary">点击下载电煤现货采购帮助文档.DOC</el-button>-->
        <!--                        <el-button type="primary">点击下载供应商注册帮助文档</el-button>-->
        <!--                    </div>-->
        <!--                </el-tab-pane>-->
      </el-tabs>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeName: '0',
      activeNames: ['1'],
      currentPage: 1,
      //分页数据
      pageIndex: 1,//当前页数
      pageSize: 10,//每页最大条数
      totalPage: 100,//总页数
      tableData: [],
      qualityTableStyle: {
        'background': 'whitesmoke',
        'font-weight': 'bold',
        'font-size': ' 12px',
        'font-family': "Microsoft YaHei",
        'color': '#333333',
        'text-align': 'center',
      },
      //
      loginFlag: false,//临时登录开关
      tableData02: [],
      //采购单位
      purchasingUnit: '',
      purchasingUnitList: [],
      //煤种
      coal: '',
      coalList: [],
      //订单状态
      orderStatus: '',
      orderStatusList: [],
      //采购日期
      procurementDate: '',
      //采购单位
      companyName: '',
      timeDate: '',//开始结束时间
    }
  },
  activated() {
    this.getCoalList();
  },
  watch: {
    activeName(val) {
      this.pageIndex = 1;
      this.pageSize = 10;
      this.coal = '';
      this.purchasingUnit = '';
      this.getList();
    }
  },
  created() {
    this.activeName = '4';
  },
  methods: {
    //转换时间格式
    formateDate(datetime) {
      function addDateZero(num) {
        return (num < 10 ? "0" + num : num);
      }
      let d = new Date(datetime);
      let formatdatetime = d.getFullYear() + '-' + addDateZero(d.getMonth() + 1) + '-' + addDateZero(d.getDate());
      return formatdatetime;
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val
      this.pageIndex = 1
      //调用查询信息接口
      this.getList()
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val
      //调用查询信息接口
      this.getList()
    },
    //搜索功能
    getSearchList() {
      this.getList()
    },
    gotoLogin() {
      this.$router.push({path: "/login"});
    },
    gotoRegist() {
      this.$router.push({path: "/register"});
    },
    //获取列表信息
    getList() {
      if (this.activeName != '2') {
        this.$fetch('tptargetinfo/list', {
          'page': this.pageIndex,
          'limit': this.pageSize,
          'outbidstate': this.activeName,
          'pproductid': this.coal,
          'companyname': this.purchasingUnit,
          'begintime': this.timeDate === ''|| this.timeDate === null ? '' : this.formateDate(this.timeDate[0]) +' ' + '00:00:00',
          'endtime': this.timeDate === '' || this.timeDate === null ? '':this.formateDate(this.timeDate[1]) + ' ' + '23:59:59'
        }, true).then((data) => {
          if (data && data.code === 0) {
            this.tableData = data.page.list;
            this.totalPage = data.page.totalCount;
          } else {
            this.$message({
              message: data.msg,
              type: 'error',
              showClose: 'true'
            })
          }
        })
      } else {
        this.$fetch('tptargetinfo/endList', {
          'page': this.pageIndex,
          'limit': this.pageSize,
          'pproductid': this.coal,
          'companyname': this.purchasingUnit,
          'begintime': this.timeDate === ''|| this.timeDate === null ? '' :this.formateDate(this.timeDate[0]) +' ' + '00:00:00',
          'endtime': this.timeDate === '' || this.timeDate === null ? '':this.formateDate(this.timeDate[1]) + ' ' + '23:59:59'
        }, true).then((data) => {
          if (data && data.code === 0) {
            this.tableData = data.page.list;
            this.totalPage = data.page.totalCount;
          } else {
            this.$message({
              message: data.msg,
              type: 'error',
              showClose: 'true'
            })
          }
        })
      }

    },
    //获取煤种下拉列表
    getCoalList() {
      this.$fetch('tpproductinfo/select', {}, true).then((data) => {
        if (data && data.code === 0) {
          this.coalList = data.auditpproductinfo;
        } else {
          this.coalList = [];
          this.$message({
            message: data.msg,
            type: 'error',
            showClose: 'true'
          })
        }
      })
    },
  },
  mounted() {
  },
}
</script>

<style scoped lang="scss">
@import "../assets/css/index.css";
@import "../assets/css/page/procurement.scss";
</style>
